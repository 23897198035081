import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type {
  ApiClientRemoteWorkDetectionDeletePayload,
  ApiClientRemoteWorkDetectionDeleteResponse,
  ApiClientRemoteWorkDetectionEditPayload,
  ApiClientRemoteWorkDetectionGetResponse,
  ApiClientRemoteWorkDetectionUpsertPayload,
  ApiClientRemoteWorkDetectionUpsertResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientRemoteWorkDetection/ApiClientRemoteWorkDetection.types';

export class ApiClientRemoteWorkDetection extends ApiClientBase {
  async create(payload: ApiClientRemoteWorkDetectionUpsertPayload) {
    return await this.fetchData<ApiClientRemoteWorkDetectionUpsertResponse>({
      queryString: 'remote-work-detection/create',
      method: 'POST',
      body: payload,
    });
  }
  async get() {
    const { data: { data } } = await this.fetchData<ApiClientRemoteWorkDetectionGetResponse>({
      queryString: 'remote-work-detection',
    });

    return data;
  }

  async delete({ id }: ApiClientRemoteWorkDetectionDeletePayload) {
    return await this.fetchData<ApiClientRemoteWorkDetectionDeleteResponse>({
      queryString: `remote-work-detection/${id}/remove`,
      method: 'DELETE',
    });
  }

  async edit({ id, ...payload }: ApiClientRemoteWorkDetectionEditPayload) {
    return await this.fetchData<ApiClientRemoteWorkDetectionUpsertResponse>({
      queryString: `remote-work-detection/${id}/update`,
      method: 'PUT',
      body: payload,
    });
  }
}
