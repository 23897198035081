import { all, fork } from 'redux-saga/effects';
import { ApprovalModelSagas } from './models/ApprovalsModel/saga';
import { EntryModelSagas } from './models/EntryModel/saga';
import { FilterModelSagas } from './models/FilterModel/saga';
import { ReportModelSagas } from './models/ReportModel/saga';
import { SettingModelSagas } from './models/SettingModel/saga';
import { TaskModelSagas } from './models/TaskModel/saga';

export default () =>
  function* rootSaga() {
    const sagas = [
      ...ReportModelSagas,
      ...TaskModelSagas,
      ...FilterModelSagas,
      ...ApprovalModelSagas,
      ...EntryModelSagas,
      ...SettingModelSagas,
    ];

    yield all(sagas.map(fork));
  };
