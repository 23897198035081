import { GroupSetting, SettingsModelStateFields } from '@/redux/models/SettingModel/types';
import { TimeServiceFactory } from '@/services/TimeServiceFactory';
import { config } from '@/config';

export const timeService = TimeServiceFactory();

// eslint-disable-next-line
// @ts-ignore
timeService.setWeekStart(
  config[SettingsModelStateFields.GROUP_SETTING][GroupSetting.WEEK_START],
);
