import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { Dict } from '@/types/types';

export class ApiClientRateType extends ApiClientBase {
  async fetchRateType() {
    const { data } = await this.fetchData<Dict<string>>({
      queryString: 'rate_type',
      apiVersion: 1,
    });

    return data;
  }
}
