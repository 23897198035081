import { fromJS } from 'immutable';
import get from 'lodash/get';
import type { PayloadAction } from '@/types/reduxTypes';
import type {
  MessagesModelState } from './types';
import {
  MessagesModelStateFields,
  MessagesModelActions,
} from './types';
import type { Reducer } from 'redux';

const initialState: MessagesModelState = fromJS({
  [MessagesModelStateFields.MODAL_VISIBLITY]: {},
});

export const messageModelReducer: Reducer<MessagesModelState, PayloadAction> = (
  state: MessagesModelState = initialState,
  action: PayloadAction = {} as PayloadAction,
): MessagesModelState => {
  switch (action.type) {
    case MessagesModelActions.CHANGE_MODAL_VISIBILITY: {
      const type = MessagesModelStateFields.MODAL_VISIBLITY;
      const name = get(action, ['payload', 'name']);
      const value = get(action, ['payload', 'value']);
      return state.setIn([type, name], value);
    }

    case MessagesModelActions.CHANGE_MODAL_TEXT: {
      const type = get(action, ['payload', 'type']);
      const name = get(action, ['payload', 'name']);
      const value = get(action, ['payload', 'value']);
      return state.setIn([type, name], value);
    }

    default: {
      return state;
    }
  }
};
