import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Notification } from '@/components/common/Notifications/Notification';
import { useNotifications } from '@/hooks/useNotifications/useNotifications';

export const NotificationsWrapper = () => {
  const { alerts } = useNotifications();

  const areAlertsToShow = useMemo(() => Array.isArray(alerts) && alerts.length, [alerts]);

  if (!areAlertsToShow) {
    return null;
  }

  return (
    createPortal(
      <div className='fixed flex flex-col top-78 md:left-1/2 z-[999999] md:-translate-x-1/2 w-full md:w-auto'>
        {
          alerts.map((alert, index) => (
            <Notification key={index} {...alert} />
          ))
        }
      </div>,
      document.body as HTMLElement,
    )
  );
};
