import type { IUseTagsQueryData } from '@/reactQuery/Tags/hooks/useQueryTags/types';
import { translate } from '@/utils/translate';
import { TAG_LIST_EMPTY_VALUE } from '@/types/types';

export const groupTags = (tagsData: IUseTagsQueryData = []) => {
  const tagsGroupedByList = {};

  tagsData.forEach((tag) => {
    if (tag) {
      if (!tagsGroupedByList[tag.tagListName ?? TAG_LIST_EMPTY_VALUE]) {
        tagsGroupedByList[tag.tagListName ?? TAG_LIST_EMPTY_VALUE] = [tag];
      } else {
        tagsGroupedByList[tag.tagListName ?? TAG_LIST_EMPTY_VALUE].push(tag);
      }
    }
  });

  const tagNames = [];

  Object.keys(tagsGroupedByList).forEach((key) => {
    tagNames.push(key !== TAG_LIST_EMPTY_VALUE ? (`${key}: ${tagsGroupedByList[key].map((tag) => tag.tagName ?? translate('ReportEntityDataProvider.no_tag')).join(', ')}`) : translate('ReportEntityDataProvider.no_tag'));
  });

  return {
    tagNames,
    tagsGroupedByList,
  };
};
