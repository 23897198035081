import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import type { AnyDict } from '@/types/types';
import { getDefaultFilterValue } from './utils';
import type { FilterModule, FilterPermissions, OrdersName, ReportFiltersState } from './types';
import { FilterName } from './types';
import { domain } from './index';

export const selectFiltersDomain = (state) => state[domain];

export const selectFiltersByModule = createSelector(
  selectFiltersDomain,
  (_, args: { module: FilterModule }) => args.module,
  (domain, module: FilterModule) => domain[module],
);

export const selectAllFilters: (state: any, filter: any) => Record<string, any> = createSelector(
  selectFiltersByModule,
  () => {
    // do nothing
  },
  (module) => {
    const result: AnyDict = {};

    Object.keys(FilterName).forEach((filterName) => {
      const name = FilterName[filterName];
      let filter = module[name];

      if (filter === undefined) {
        filter = getDefaultFilterValue(name);
      }

      result[name] = filter;
    });

    return result;
  },
);

export const selectFiltersByNames = createSelector(
  selectFiltersByModule,
  (_, args: { names: FilterName[] }) => args.names,
  (module, names: FilterName[]) => {
    const resultArray: AnyDict = {};

    names.forEach((name) => {
      let filter = module[name];

      if (!filter) {
        filter = getDefaultFilterValue(name);
      }

      resultArray[name] = filter;
    });

    return resultArray;
  },
);

export const selectFiltersByName = createSelector(
  selectFiltersByModule,
  (_, args: { name: FilterName | OrdersName | '' }) => args.name,
  (module, name: FilterName | OrdersName | '') => {
    let result = module[name];

    if (name === '') {
      if (!result) {
        result = getDefaultFilterValue(name);
      }
    }

    return result;
  },
);

export const selectFilterValue = createSelector(
  selectFiltersByModule,
  (_, args: { name: FilterName | FilterPermissions }) => args.name,
  (module, name: FilterName | FilterPermissions) => module[name],
);

export const useUserDisplayedStatus = () => useSelector(state => state['FilterModel']['users']?.['statusOfDisplayedUsers']);
export const useSelectTaskPickerStatus = () => useSelector(state => state['FilterModel']['expanses']['taskPickerStatus']) as ReportFiltersState['expanses']['taskPickerStatus'];
