import type { Maybe } from '@/types/types';
import type { BrowserStorageServiceInterface } from './interface';

export class SafeLocalStorageService
implements BrowserStorageServiceInterface {
  getItem<T>(key: string): Promise<Maybe<T>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      // @ts-ignore
      resolve(window.SafeLocalStorage.getItem(key));
    });
  }

  setItem<T>(key: string, item: T): Promise<Maybe<T>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      // @ts-ignore
      resolve(window.SafeLocalStorage.setItem(key, item));
    });
  }

  deleteItem(key: string): Promise<Maybe<Event>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      //@ts-ignore
      resolve(window.SafeLocalStorage.removeItem(key));
    });
  }
}
