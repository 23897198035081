import { createSelector } from '@reduxjs/toolkit';
import { getDefaultValue } from './utils';
import type { TaskModelState } from './types';
import { TaskModelStateFields } from './types';
import { domain } from './index';

export const selectDomain = (state) => state[domain] as TaskModelState;

export const selectTasks = createSelector(
  selectDomain,
  (_, args: { field: TaskModelStateFields }) => args.field,
  (domain: any, field) => domain.get(field) || {},
);

export const selectTaskById = createSelector(
  selectTasks,
  (_, args: { id }) => args.id,
  (tasks, id: number) => {
    const task = tasks[id];
    if (!task) {
      return getDefaultValue();
    }

    return tasks[id];
  },
);

export const selectTasksLoadingStatus = createSelector(
  selectDomain,
  (domain) => domain.get(TaskModelStateFields.TASKS_LOADING_STATUS),
);
