import { createTransform } from 'redux-persist';

/**
 * @link https://github.com/rt2zz/redux-persist/issues/82
 */
export const transformPersistStringDateToObject = () => createTransform(JSON.stringify as never, (toRehydrate: string, reducer) => JSON.parse(toRehydrate, (key, value) => {
  if (reducer === 'MainMenuModel' && key === 'isMobileOpen') {
    return false;
  }

  return typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
    ? new Date(value)
    : value;
}));
