import { action } from 'typesafe-actions';
import type { Analytics } from '@/services/Analitics/Analytics';
import { EntryActions } from './types';

export const editEntry = (
  payload,
) => action(EntryActions.EDIT_ENTRY, payload);
export const editEntryTags = (
  payload,
) => action(EntryActions.EDIT_ENTRY_TAG, payload);

export const deleteEntry = (
  id,
  analytics?: Analytics | null,
) => action(EntryActions.DELETE_ENTRY, {
  id,
  analytics,
});

export const deleteEntrySuccess = (
  id,
) => action(EntryActions.DELETE_ENTRY_SUCCESS, {
  id,
});

export const editEntrySuccess = (
  payload,
) => action(EntryActions.EDIT_ENTRY_SUCCESS, payload);

export const editEntryTagsSuccess = (
  payload,
) => action(EntryActions.EDIT_ENTRY_TAG_SUCCESS, payload);
