import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { ICurrencies } from './ApiClientCurrency.types';

export class ApiClientCurrency extends ApiClientBase {

  async get() {
    const { data } = await this.fetchData<ICurrencies>({
      queryString: 'currency',
      apiVersion: 1,
    });

    return data;
  }
}
