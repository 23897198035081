import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type {
  IHideElementsInMainMenuParams,
  IHideElementsInMainMenuResponse,
  IPinLinksInMainMenuParams,
  IPinLinksInMainMenuResponse,
} from './ApiClientMainMenu.types';

export class ApiClientMainMenu extends ApiClientBase {
  async pinLinksInMainMenu(params: IPinLinksInMainMenuParams) {
    const { data } = await this.fetchData<IPinLinksInMainMenuResponse>({
      queryString: 'menu/pinned-links',
      method: 'PUT',
      body: params,
      apiVersion: 3,
    });

    return data;
  }

  async hideLinksInMainMenu(params: IHideElementsInMainMenuParams) {
    const { data } = await this.fetchData<IHideElementsInMainMenuResponse>({
      queryString: 'menu/hidden-elements',
      method: 'PUT',
      body: params,
      apiVersion: 3,
    });

    return data;
  }
}
