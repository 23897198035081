import omit from 'lodash/omit';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type {
  IAddAttachmentToExpenseParams,
  IAddExpenseCategory,
  IAddExpenseResponse,
  IAddOrEditExpenseCategoryResponse,
  IArchiveCategoryParams,
  IArchiveCategoryResponse,
  IDeleteAttachmentFromExpenseParams,
  IDeleteAttachmentFromExpenseResponse,
  IDeleteExpenseParams,
  IDeleteExpenseResponse,
  IEditExpenseCategoryParams,
  IEditExpenseResponse,
  IExpense,
  IExpenseAddParams,
  IExpenseAttachmentsResponse,
  IExpenseEditParams,
  IExpensesResponse,
  IGetExpenseAttachmentParams,
  IGetExpenseCategoriesResponse,
  IGetExpensesParams,
} from './ApiClientExpense.types';
import { filterEmptyValuesFromGetExpensesRequest, mapDateValuesForTcDateFormatStrings } from './ApiClientExpense.utils';

export class ApiClientExpense extends ApiClientBase {
  async getCategories() {
    const { data } = await this.fetchData<IGetExpenseCategoriesResponse>({
      queryString: 'expense/category',
    });

    return data;
  }

  async addCategory(category: IAddExpenseCategory) {
    const { data } = await this.fetchData<IAddOrEditExpenseCategoryResponse>({
      queryString: 'expense/category',
      method: 'POST',
      body: omit(category, ['id', 'createdAt', 'updatedAt']),
    });

    return data;
  }

  async editCategory(category: IEditExpenseCategoryParams) {
    const { data } = await this.fetchData<IAddOrEditExpenseCategoryResponse>({
      queryString: `expense/category/${category.id}`,
      method: 'PUT',
      body: category,
    });

    return data;
  }

  async archiveCategory(category: IArchiveCategoryParams) {
    const { data } = await this.fetchData<IArchiveCategoryResponse>({
      queryString: `expense/category/${category.id}/archive`,
      method: 'PATCH',
    });

    return data;
  }

  async getExpenses(params?: IGetExpensesParams) {
    const { data } = await this.fetchData<IExpensesResponse>({
      queryString: 'expense/list',
      method: 'POST',
      body: mapDateValuesForTcDateFormatStrings(filterEmptyValuesFromGetExpensesRequest(params)),
    });

    return data;
  }

  async editExpense(expense: IExpenseEditParams) {
    const { data } = await this.fetchData<IEditExpenseResponse>({
      queryString: `expense/${expense.id}`,
      method: 'PUT',
      body: omit(expense, ['id', 'createdAt', 'updatedAt']),
    });

    return data;
  }

  async addExpense(expense: IExpenseAddParams) {
    const { data } = await this.fetchData<IAddExpenseResponse>({
      queryString: 'expense',
      method: 'POST',
      body: omit(expense, ['id', 'createdAt', 'updatedAt']),
    });

    return data;
  }

  async deleteExpense({ id }: IDeleteExpenseParams) {
    const { data } = await this.fetchData<IDeleteExpenseResponse>({
      queryString: `expense/${id}`,
      method: 'DELETE',
    });

    return data;
  }

  async addAttachment({ id, file }: IAddAttachmentToExpenseParams) {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.fetchData<IExpense>({
      queryString: `expense/${id}/attachment/upload`,
      method: 'POST',
      body: formData,
    });

    return data;
  }

  async getAttachments({ id }: IGetExpenseAttachmentParams) {
    const { data } = await this.fetchData<IExpenseAttachmentsResponse>({
      queryString: `expense/${id}/attachment`,
    });

    return data;
  }

  async deleteAttachment({ expenseId, attachmentId }: IDeleteAttachmentFromExpenseParams) {
    const { data } = await this.fetchData<IDeleteAttachmentFromExpenseResponse>({
      queryString: `expense/${expenseId}/attachment/${attachmentId}/delete`,
      method: 'DELETE',
    });

    return data;
  }
}
