import delay from 'delay';
import { List } from 'immutable';
import { cancelled, put, select } from 'redux-saga/effects';
import { selectAllFilters } from '@/redux/models/FilterModel/selectors';
import { archivedDictionary, FilterModule, FilterName } from '@/redux/models/FilterModel/types';
import {
  dataIsLoadingUpdated,
  fetchDetailedReportSuccess,
  fetchGroupedSummaryReportMetaSuccess,
  fetchReportStatusUpdated,
  initDetailedReport,
  saveDetailedReportId,
  setReportStatus,
} from '@/redux/models/ReportModel/actions';
import { selectReportData } from '@/redux/models/ReportModel/selectors';
import {
  BackendReportStatus,
  DetailedReportStatus,
  ReportModelStateFields,
  ReportType,
} from '@/redux/models/ReportModel/types';
import type { SummaryReportRowInterface } from '@/components/reports/SummaryReport/SummaryReportTable/SummaryReportRowInterface';
import { reportEntityDataProvider } from '@/services/instances/reportEntityDataProvider';
import { restApiClient } from '@/services/instances/restApiClient';
import { timeService } from '@/services/instances/timeService';
import { TIMEFRAME_DATE_FORMAT } from '@/utils/constants';
import type { PartialPayloadAction } from '@/types/reduxTypes';
import type { NestedListItem } from '@/types/types';
import { DetailedSortColumnTypes, ReportEntityType } from '@/types/types';

const REPORT_GENERATE_WAIT_TIME_MILLISECONDS = 5000;
const REPORT_INIT_RETRY_TIME_MILLISECONDS = 3000;
let errorStatus = null;

const TYPE_KEY = 'type';
const INIT_PAYLOAD = {
  [TYPE_KEY]: ReportType.DETAILED_REPORT,
};

export function* onFetchDetailedReport({
  payload,
}: PartialPayloadAction<{
  groupBy: string;
  orderBy;
  timeFrame;
}>) {
  const controller = new AbortController();
  const { signal } = controller;

  try {
    if (payload) {
      yield put(fetchReportStatusUpdated(DetailedReportStatus.STARTING));

      if (!payload['fileDownload']) {
        yield put(dataIsLoadingUpdated(true));
      }
      const payloadToInit = { ...INIT_PAYLOAD };
      payloadToInit['timeFrameStart'] = timeService.dateToFormat(payload.timeFrame.start, TIMEFRAME_DATE_FORMAT);
      payloadToInit['timeFrameEnd'] = timeService.dateToFormat(payload.timeFrame.end, TIMEFRAME_DATE_FORMAT);

      if (payload[FilterName.BILLABLE] !== null) {
        payloadToInit[FilterName.BILLABLE] = payload[FilterName.BILLABLE];
      }

      if (payload[FilterName.INVOICED] !== null) {
        payloadToInit[FilterName.INVOICED] = payload[FilterName.INVOICED];
      }

      if (payload[FilterName.APPROVAL_STATUS] !== null) {
        payloadToInit[FilterName.APPROVAL_STATUS] = payload[FilterName.APPROVAL_STATUS];
      }

      if (payload[FilterName.USERS].length > 0) {
        payloadToInit[FilterName.USERS] = payload[FilterName.USERS];
      }

      if (payload[FilterName.TASKS].length > 0) {
        payloadToInit[FilterName.TASKS] = payload[FilterName.TASKS];
      }

      if (payload[FilterName.TAGS].length > 0) {
        payloadToInit[FilterName.TAGS] = payload[FilterName.TAGS];
      }

      if (payload[FilterName.NOTE] !== null) {
        payloadToInit[FilterName.NOTE] = payload[FilterName.NOTE];
      }

      if (payload[FilterName.ARCHIVED_TASKS] !== null) {
        const archivedPayload = payload[FilterName.ARCHIVED_TASKS];
        payloadToInit[FilterName.ARCHIVED_TASKS] = archivedDictionary[archivedPayload];
      }

      let reportInitResponse = null;
      let waitMs = 0;

      do {
        reportInitResponse = yield restApiClient
          .reports
          .initializeReport(signal, payloadToInit)
          .then((result) => result)
          .catch((error) => {
            errorStatus = Number(error.message);
          });

        if (errorStatus) {
          yield put(setReportStatus(errorStatus));
          yield put(dataIsLoadingUpdated(false));
          return;
        }

        yield delay(waitMs);
        waitMs = REPORT_INIT_RETRY_TIME_MILLISECONDS;
      } while (!reportInitResponse);

      if (!reportInitResponse) {
        // todo add max attempts
        console.warn('Error initializing report. Reinitializing report again...');
        yield refreshReport();
        return;
      }

      const reportId = reportInitResponse.id;
      yield put(saveDetailedReportId(reportId));

      yield put(fetchReportStatusUpdated(DetailedReportStatus.WAITING));

      let backendStatusOfReport = null;
      waitMs = 0;
      do {
        const { data: statusResponse } = yield restApiClient.reports.fetchReportStatus(signal, reportId);

        if (!statusResponse) {
          console.warn('Error initializing report. Reinitializing report again...');
          yield refreshReport();
          return;
        }

        backendStatusOfReport = statusResponse.id;

        yield delay(waitMs);
        waitMs = REPORT_GENERATE_WAIT_TIME_MILLISECONDS;
      } while (backendStatusOfReport !== BackendReportStatus.Generated);

      yield put(fetchReportStatusUpdated(DetailedReportStatus.GENERATED));

      yield put(fetchReportStatusUpdated(DetailedReportStatus.FETCHING));

      const metaData = yield restApiClient
        .reports
        .fetchMeta(signal, reportId, ReportType.DETAILED_REPORT)
        .then((result) => result)
        .catch((error) => {
          errorStatus = Number(error.message);
        });

      if (!metaData) {
        yield put(initDetailedReport(payload));
      }

      if (errorStatus) {
        yield put(setReportStatus(errorStatus));
        yield put(dataIsLoadingUpdated(false));
      }

      yield put(fetchGroupedSummaryReportMetaSuccess({ metaData }));
      const { totalTime } = metaData;

      if (metaData?.entriesCount < 1000) {
        const reportResponse = yield restApiClient
          .reports
          .fetchDetailedReport(signal, reportId)
          .then((result) => result)
          .catch((error) => {
            errorStatus = Number(error.message);
          });

        if (errorStatus) {
          yield put(setReportStatus(errorStatus));
          yield put(dataIsLoadingUpdated(false));
        }

        const resultData = reportResponse.data ?? [];

        yield put(fetchReportStatusUpdated(DetailedReportStatus.FETCHED));

        let data: List<NestedListItem> = List();
        resultData.forEach((item) => {
          item[DetailedSortColumnTypes.TASK_NAME] = reportEntityDataProvider.getItemName(item.taskId, ReportEntityType.TASK);
          item[DetailedSortColumnTypes.USER_NAME] = reportEntityDataProvider.getItemName(item.userId, ReportEntityType.USER);
          item[DetailedSortColumnTypes.TIMESTAMP] = new Date(`${item.date} ${item.startTime}`);
          if (typeof item[DetailedSortColumnTypes.TAGS] === 'object' &&
            !Array.isArray(item[DetailedSortColumnTypes.TAGS])
          ) {
            item[DetailedSortColumnTypes.TAGS] = [...Object.values(item[DetailedSortColumnTypes.TAGS])].flat();
          }

          data = data.push(item as SummaryReportRowInterface);
        });
        const reportData = {
          data,
          totalTime,
        };

        yield put(fetchReportStatusUpdated(DetailedReportStatus.EMPTY_STATUS));
        yield put(fetchDetailedReportSuccess({ reportData }));
        yield put(fetchReportStatusUpdated(DetailedReportStatus.SAVED));
      }
      yield put(dataIsLoadingUpdated(false));
    }
  } finally {
    if (yield cancelled()) {
      controller.abort('Fetch detailed report cancelled');
    }
  }
}

export function* refreshReport() {
  const reportFilters = yield select(selectAllFilters, {
    module: FilterModule.REPORTS,
  });

  yield put(initDetailedReport(reportFilters));
}

export function* onDeleteEntrySuccess() {
  const controller = new AbortController();
  const { signal } = controller;

  const response = yield restApiClient.reports.invalidateReportCache(signal);

  if (!response) {
    return;
  }

  const status = yield select(selectReportData, {
    field: ReportModelStateFields.REPORT_STATUS,
  });

  if (!status) {
    return;
  }

  yield put(fetchReportStatusUpdated(DetailedReportStatus.INVALIDATED));
}

export function* onDeleteEntry({ payload }: PartialPayloadAction<{
  id: number
}>) {
  const { id } = payload;

  const reportData = yield select(selectReportData, {
    field: ReportModelStateFields.REPORT_DETAILED,
  });

  const toRemove = reportData.data.find(entry => entry.id === id);

  if (!toRemove) {
    return;
  }

  reportData.totalTime -= toRemove.duration;
  reportData.data = reportData.data.filter((entry) => entry.id !== id);

  yield put(fetchDetailedReportSuccess({ reportData }));
}
