import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type {
  AddSurveyAnswerParams,
  AddSurveyResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientSurvey/ApiClientSurvey.types';

export class ApiClientSurvey extends ApiClientBase {
  async addSurveyAnswer(params: AddSurveyAnswerParams) {
    const { data } = await this.fetchData<AddSurveyResponse>({
      queryString: 'survey/answers',
      apiVersion: 3,
      method: 'POST',
      body: params,
    });

    return data;
  }
}
