import { action } from 'typesafe-actions';
import { ApprovalModelActions } from './types';

export const fetchApprovals = (args) => action(ApprovalModelActions.FETCH_APPROVALS, args);

export const loadingApprovals = (args) => action(ApprovalModelActions.LOADING_APPROVALS, args);

export const fetchApprovalsSuccess = (args) => action(ApprovalModelActions.FETCH_APPROVALS_SUCCESS, args);

export const fetchActionApprovals = (args) => action(ApprovalModelActions.FETCH_ACTION_APPROVALS, args);

export const updateApprovals = (data) => action(ApprovalModelActions.UPDATE_APPROVALS, data);
