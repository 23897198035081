import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { ApiClientAddonsSalesforceSettingsResponse } from './ApiClientAddons.types';

export class ApiClientAddons extends ApiClientBase {
  async fetchSalesforceSettings() {
    return await this.fetchData<ApiClientAddonsSalesforceSettingsResponse>({
      queryString: 'addons/salesforce/cases',
      apiVersion: 0,
    });
  }
}
