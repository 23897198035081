import isEmpty from 'lodash/isEmpty';
import { fork, put, select, takeLatest } from 'redux-saga/effects';
import { fetchDetailedReportSuccess, refreshDetailedReport } from '@/redux/models/ReportModel/actions';
import { selectReportData } from '@/redux/models/ReportModel/selectors';
import { ReportModelStateFields } from '@/redux/models/ReportModel/types';
import type { Analytics } from '@/services/Analitics/Analytics';
import { notificationService } from '@/services/instances/notificationService';
import { reportEntityDataProvider } from '@/services/instances/reportEntityDataProvider';
import { restApiClient } from '@/services/instances/restApiClient';
import { translate } from '@/utils/translate';
import type { PartialPayloadAction } from '@/types/reduxTypes';
import { DetailedSortColumnTypes, ReportEntityType } from '@/types/types';
import type { BackendEntry, Entry } from './types';
import { EntryActions } from './types';
import { deleteEntrySuccess, editEntrySuccess, editEntryTagsSuccess } from './actions';

export const EntryModelSagas = [
  function* () {
    yield fork(function* () {
      yield takeLatest(EntryActions.EDIT_ENTRY, onEditEntry);
    });
    yield fork(function* () {
      yield takeLatest(EntryActions.EDIT_ENTRY_SUCCESS, onEditEntrySuccess);
    });
    yield fork(function* () {
      yield takeLatest(EntryActions.DELETE_ENTRY, onDeleteEntry);
    });
    yield fork(function* () {
      yield takeLatest(EntryActions.EDIT_ENTRY_TAG, onEditEntryTag);
    });
    yield fork(function* () {
      yield takeLatest(EntryActions.EDIT_ENTRY_TAG_SUCCESS, onEditEntryTagSuccess);
    });
  },
];

export function* onEditEntry(
  {
    payload,
  }: PartialPayloadAction<Entry>) {

  if (payload.id === undefined) {
    return;
  }
  if (Object.keys(payload).length < 2) {
    return;
  }

  let entry = {
    id: payload.id,
  } as BackendEntry;

  if (payload.startTime) {
    entry.start_time = payload.startTime;
  }
  if (payload.endTime) {
    entry.end_time = payload.endTime;
  }
  if (payload.duration) {
    entry.duration = payload.duration;
  }
  if (payload.date) {
    entry.date = payload.date;
  }
  if (payload.note || payload.note === null) {
    entry.note = payload.note;
  }
  if (payload.description) {
    entry.description = payload.description;
  }
  if (payload.invoiceId) {
    entry.invoiceId = payload.invoiceId;
  }
  if (payload.taskId) {
    entry.task_id = payload.taskId;
  }
  if (isEmpty(payload.billable)) {
    entry.billable = payload.billable;
  }

  if (payload.analytics) {
    entry = { ...entry, ...payload.analytics.getAttributes() };
  }

  const controller = new AbortController();
  const { signal } = controller;
  try {
    const entryAfterEdit = yield restApiClient.entries.editEntry(signal, entry);

    if (entryAfterEdit.status !== 200) {
      throw new Error(entryAfterEdit?.data?.message ?? `${entryAfterEdit}`);
    }

    yield put(editEntrySuccess(entryAfterEdit.data));
  } catch (e) {
    notificationService.error(e.message);
  }
}

export function* onEditEntrySuccess(
  {
    payload,
  }: PartialPayloadAction,
) {
  if (!payload) {
    return;
  }

  // update Detailed report
  const reportData = yield select(selectReportData, { field: ReportModelStateFields.REPORT_DETAILED });

  reportData.data.forEach((item) => {
    if (item.id === parseInt(payload.entry_id)) {
      item.taskId = parseInt(payload.task_id);

      const startTimePayload = payload.start_time_hour.length <= 4
        ? (`0${payload.start_time_hour}`)
        : payload.start_time_hour;

      const endTimePayload = payload.end_time_hour.length <= 4
        ? (`0${payload.end_time_hour}`)
        : payload.end_time_hour;

      item[DetailedSortColumnTypes.TASK_NAME] = reportEntityDataProvider.getItemName(item.taskId, ReportEntityType.TASK);
      item[DetailedSortColumnTypes.USER_NAME] = reportEntityDataProvider.getItemName(item.userId, ReportEntityType.USER);
      item[DetailedSortColumnTypes.TIMESTAMP] = new Date(`${item.date}T${item.startTime}`);
      item[DetailedSortColumnTypes.NOTE] = payload.note;
      item[DetailedSortColumnTypes.DATE] = payload.date;
      item[DetailedSortColumnTypes.DURATION] = parseInt(payload.time_span);
      item[DetailedSortColumnTypes.START_TIME] = startTimePayload;
      item[DetailedSortColumnTypes.END_TIME] = endTimePayload;
      item[DetailedSortColumnTypes.TAGS] = payload.tags.map(({ tagId }) => tagId);
      item[DetailedSortColumnTypes.BILLABLE] = payload.billable || false;
    }
  });

  yield put(fetchDetailedReportSuccess({ reportData }));
  yield put(refreshDetailedReport());

  const controller = new AbortController();
  const { signal } = controller;
  yield restApiClient.reports.invalidateReportCache(signal);
}

export function* onDeleteEntry(
  {
    payload,
  }: PartialPayloadAction<{
    id: number
    analytics?: Analytics | null
  }>) {
  const controller = new AbortController();
  const { signal } = controller;

  let params = { id: payload.id };
  if (payload.analytics) {
    params = { id: payload.id, ...payload.analytics.getAttributes() };
  }
  const response = yield restApiClient.entries.deleteEntry(signal, params);

  if (!response) {
    notificationService.error(
      '<i style=\'font-size: 24px; margin-right: 10px; vertical-align: middle;\' class=\'far fa-exclamation-triangle\' aria-hidden=\'true\'></i>\n' +
      `<span style='margin-right: 100px;'>${translate('EntryModel.entry_not_deleted')}</span>` +
      `<a style='cursor: pointer;' onClick='window.location.reload()'>${translate('EntryModel.refresh')}</a>`,
      undefined,
      undefined,
      true,
    );

    return;
  }

  yield put(deleteEntrySuccess(payload.id));
}

export function* onEditEntryTag(
  {
    payload,
  }: PartialPayloadAction<{
    id: number,
    tags: number[],
    oldTags: number[]
    analytics?: Analytics | null
  }>) {
  const { id, tags, oldTags, analytics } = payload;
  const controller = new AbortController();
  const { signal } = controller;

  let additionalParams = {};
  if (analytics) {
    additionalParams = analytics.getAttributes();
  }

  const tagToRemove = oldTags.filter((tagId) => !tags.includes(tagId));
  const tagToAdd = tags.filter((tagId) => !oldTags.includes(tagId));
  if (tagToAdd.length) {
    try {
      yield restApiClient.entries.addEntryTags(signal, id, tagToAdd, additionalParams);
    } catch (e) {
      notificationService.error(e.message);
      return;
    }
  }

  if (tagToRemove.length) {
    try {
      yield restApiClient.entries.deleteEntryTags(signal, id, tagToRemove, additionalParams);
    } catch (e) {
      notificationService.error(e.message);
      return;
    }
  }

  yield put(editEntryTagsSuccess({ id, tags }));
}

export function* onEditEntryTagSuccess(
  {
    payload,
  }: PartialPayloadAction<{
    id: number,
    tags: number[]
  }>) {
  const controller = new AbortController();
  const { signal } = controller;

  const { id, tags } = payload;

  const reportData = yield select(selectReportData, { field: ReportModelStateFields.REPORT_DETAILED });
  reportData.data.forEach((item) => {
    if (item.id === id) {
      item[DetailedSortColumnTypes.TAGS] = tags;
    }
  });

  yield put(fetchDetailedReportSuccess({ reportData }));

  yield restApiClient.reports.invalidateReportCache(signal);
}
