import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { MainMenuModel } from '@/redux/models/MainMenuModel';
import { transformPersistStringDateToObject } from '@/redux/utils.store';
import { REDUX_CACHE_BUSTER } from '@/utils/constants';
import { domain as FilterModel } from './models/FilterModel';
import { rootReducers } from './reducer';
import sagas from './saga';

// reducers
const reducer = rootReducers({});

// saga
const sagaMiddleware = createSagaMiddleware();

// middlewares
const middleware = [thunk, sagaMiddleware];

// persist
const persistedReducer = persistReducer({
  key: REDUX_CACHE_BUSTER,
  storage,
  whitelist: [FilterModel, MainMenuModel],
  transforms: [transformPersistStringDateToObject()],
}, reducer);

// store
export const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: import.meta.env.MODE === 'development',
});

// sagas run
// const sagas = require('./saga').default();
sagaMiddleware.run(sagas());

export const persistor = persistStore(store);
