import isNumber from 'lodash/isNumber';
import { addLeadingZero, convertEnumValuesToArray } from '@/utils/common';
import { DurationFormat, TimeUnits } from '@/types/types';
import type { Duration } from './types';

export const parseDurationToFormat = (
  duration: Duration,
  format: DurationFormat,
  firstTimeUnit: TimeUnits,
  lastTimeUnit: TimeUnits,
): string => {

  const units = convertEnumValuesToArray(TimeUnits);
  const getUnitValue = getUnitValueFunction(duration, format);
  const startIndex = units.indexOf(firstTimeUnit);
  const endIndex = units.indexOf(lastTimeUnit) + 1;
  const separator = getSeparator(format);

  return units.slice(startIndex, endIndex).map(getUnitValue).join(separator);
};

const getUnitValueFunction = (
  duration: Duration,
  format: DurationFormat,
): (unit: TimeUnits, index: number, arr: Array<TimeUnits>) => string => {
  switch (format) {
    case DurationFormat.FORMAT_CLASSIC:
    case DurationFormat.FORMAT_CLASSIC_WITH_SECONDS: {
      let addLeadingZero = false;
      return (unit) => {
        const value = duration[unit];
        let result = '';

        result = `${value < 10 && addLeadingZero ? '0' : ''}${value}${unit.charAt(0)}`;
        addLeadingZero = true;

        return result;
      };
    }
    case DurationFormat.FORMAT_HH_MM:
    case DurationFormat.FORMAT_HH_MM_SS: {
      return (unit, index) => {
        const value = duration[unit];

        return isNumber(value) && value >= 0
          ? `${index > 0 ? ':' : ''}${index > 0 ? addLeadingZero(value) : value}`
          : '';
      };
    }
    default: {
      return (unit) => `${duration[unit]}` || '';
    }
  }
};

const getSeparator = (format: DurationFormat) => {
  switch (format) {
    case DurationFormat.FORMAT_CLASSIC:
    case DurationFormat.FORMAT_CLASSIC_WITH_SECONDS: {
      return ' ';
    }
    default:
      return '';
  }
};
