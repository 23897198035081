import { createSlice } from '@reduxjs/toolkit';
import type { IMainMenuState } from '@/redux/models/MainMenuModel/MainMenuModel.types';
import type { PayloadAction } from '@reduxjs/toolkit';

export const initialState: IMainMenuState = {
  isMobileOpen: false,
  isMobileMenu: false,
  isExpanded: true,
  isHovered: false,
  pinned: [],
  hidden: [],
  disabledModules: [],
  isShowHiddenElements: false,
  collapsedSections: {},
  modulesFromHigherPlan: [],
};

export const MainMenuSlice = createSlice({
  name: 'mainMenu',
  initialState,
  reducers: {
    MOBILE_MENU_OPEN: (state, action: PayloadAction<{
      isMobileOpen: IMainMenuState['isMobileOpen'];
    }>) => {
      const { isMobileOpen } = action.payload;

      state['isMobileOpen'] = isMobileOpen;
    },
    IS_MOBILE_MENU: (state, action: PayloadAction<{
      isMobileMenu: IMainMenuState['isMobileMenu'];
    }>) => {
      const { isMobileMenu } = action.payload;

      state['isMobileMenu'] = isMobileMenu;
    },
    EXPAND_MENU: (state, action: PayloadAction<{
      isExpand: IMainMenuState['isExpanded'];
    }>) => {
      const { isExpand } = action.payload;

      state['isExpanded'] = isExpand;
    },
    HOVER_MENU: (state, action: PayloadAction<{
      isHover: IMainMenuState['isHovered'];
    }>) => {
      const { isHover } = action.payload;

      state['isHovered'] = isHover;
    },
    SET_PINNED: (state, action: PayloadAction<{
      pinned: IMainMenuState['pinned'];
    }>) => {
      const { pinned } = action.payload;

      state['pinned'] = pinned;
    },
    SET_HIDDEN: (state, action: PayloadAction<{
      hidden: IMainMenuState['hidden'];
    }>) => {
      const { hidden } = action.payload;

      state['hidden'] = hidden;
    },
    SET_DISABLED_MODULES: (state, action: PayloadAction<{
      disabledModules: IMainMenuState['hidden'];
    }>) => {
      const { disabledModules } = action.payload;

      state['disabledModules'] = disabledModules;
    },
    SET_MODULES_FROM_HIGHER_PLAN: (state, action: PayloadAction<{
      modules: IMainMenuState['modulesFromHigherPlan'];
    }>) => {
      const { modules } = action.payload;

      state['modulesFromHigherPlan'] = modules;
    },
    SET_SHOW_HIDDEN_ELEMENTS: (state, action: PayloadAction<{
      isShowHiddenElements: IMainMenuState['isShowHiddenElements'];
    }>) => {
      const { isShowHiddenElements } = action.payload;

      state['isShowHiddenElements'] = isShowHiddenElements;
    },
    COLLAPSE_SECTIONS: (state, action: PayloadAction<{
      sections: IMainMenuState['collapsedSections'];
    }>) => {
      const { sections } = action.payload;

      state['collapsedSections'] = sections;
    },
  },
});
