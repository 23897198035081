import chartMixedCustom from './chartMixedCustom.svg';
import chartUserCustomDays from './chartUserCustomDays.svg';
import chartUserCustomProjects from './chartUserCustomProjects.svg';
import chartUserCustomTasks from './chartUserCustomTasks.svg';
import chartUserCustomTasksAndDays from './chartUserCustomTasksAndDays.svg';
import magnifyingGlassCustomQuestion from './magnifyingGlassCustomQuestion.svg';
import magnifyingGlassDollarCustomCosts from './magnifyingGlassDollarCustomCosts.svg';
import magnifyingGlassDollarCustomEstimates from './magnifyingGlassDollarCustomEstimates.svg';
import magnifyingGlassDollarCustomMargin from './magnifyingGlassDollarCustomMargin.svg';
import magnifyingGlassDollarCustomRevenue from './magnifyingGlassDollarCustomRevenue.svg';

export const customIcons = {
  'chart-user-custom-tasks': chartUserCustomTasks,
  'chart-user-custom-days': chartUserCustomDays,
  'chart-user-custom-tasks-and-days': chartUserCustomTasksAndDays,
  'chart-user-custom-projects': chartUserCustomProjects,
  'magnifying-glass-dollar-custom-estimates': magnifyingGlassDollarCustomEstimates,
  'magnifying-glass-dollar-custom-revenue': magnifyingGlassDollarCustomRevenue,
  'magnifying-glass-dollar-custom-costs': magnifyingGlassDollarCustomCosts,
  'magnifying-glass-dollar-custom-margin': magnifyingGlassDollarCustomMargin,
  'magnifying-glass-custom-question': magnifyingGlassCustomQuestion,
  'chart-mixed-custom': chartMixedCustom,
};
