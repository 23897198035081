import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import {
  filterEmptyValuesFromGetExpensesRequest,
  mapDateValuesForTcDateFormatStrings,
} from '@/services/RestApiClientFactory/modules/ApiClientExpense/ApiClientExpense.utils';
import type {
  ApiClientExporterGetFileParams,
  ApiClientExporterGetFileResponse,
  ApiClientExporterGetStatusParams,
  ApiClientExporterGetStatusResponse,
  ApiClientExporterInitParams,
  ApiClientExporterInitResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientExporter/ApiClientExporter.type';
import { ExportStatusError } from '@/services/RestApiClientFactory/modules/ApiClientExporter/errors/ExportStatusError';

export class ApiClientExporter extends ApiClientBase {

  async init(body: ApiClientExporterInitParams, signal?: AbortSignal) {
    return await this.fetchData<ApiClientExporterInitResponse>({
      method: 'POST',
      queryString: 'exporter',
      body: {
        ...body,
        filters: mapDateValuesForTcDateFormatStrings(filterEmptyValuesFromGetExpensesRequest(body.filters)),
      },
      signal,
    });
  }

  async getStatus({ hash }: ApiClientExporterGetStatusParams, signal?: AbortSignal) {
    const res = await this.fetchData<ApiClientExporterGetStatusResponse>({
      queryString: `exporter/${hash}/status`,
      signal,
    });

    if (res.data?.data.status === 4) {
      throw new ExportStatusError('Export failed');
    }

    return res;
  }

  async getFile({ hash }: ApiClientExporterGetFileParams, signal?: AbortSignal) {
    const result = await this.fetchData<ApiClientExporterGetFileResponse>({
      queryString: `exporter/${hash}/file`,
      signal,
    });

    return result.data;
  }
}
