import { endOfWeek, startOfWeek } from 'date-fns';
import { fromJS } from 'immutable';
import _get from 'lodash/get';
import { config } from '@/config';
import type { TimeSheetModelState, TimeSheetPayloadAction } from './types';
import { TimeSheetModelActions, TimeSheetModelStateFields } from './types';
import type { Reducer } from 'redux';

const presentDay = new Date();

const weekStartOn = Number(config?.groupSetting?.weekStart || '1') as 0 | 1;

const initialState = fromJS({})
  .setIn([TimeSheetModelStateFields.WEEK_BETWEEN_DATE], [
    startOfWeek(presentDay, { weekStartsOn: weekStartOn }),
    endOfWeek(presentDay, { weekStartsOn: weekStartOn }),
  ])
  .setIn([TimeSheetModelStateFields.WEEK_ACTIVE_USER], config?.userId);

export const timeSheetModelReducer: Reducer<TimeSheetModelState, TimeSheetPayloadAction> = (
  state: TimeSheetModelState = initialState,
  action: TimeSheetPayloadAction = {} as TimeSheetPayloadAction,
): TimeSheetModelState => {
  switch (action.type) {

    case TimeSheetModelActions.UPDATE_WEEK_BETWEEN_DATE: {
      const { data } = _get(action, ['payload']);

      return state.setIn([TimeSheetModelStateFields.WEEK_BETWEEN_DATE], data);
    }

    case TimeSheetModelActions.UPDATE_WEEK_ACTIVE_USER: {
      const userId = _get(action, ['payload']);

      return state.setIn([TimeSheetModelStateFields.WEEK_ACTIVE_USER], userId);
    }

    default: {
      return state;
    }
  }
};
