export enum PredefinedDateRange {
  TODAY = 'calendar.today',
  YESTERDAY = 'calendar.yesterday',
  THIS_WEEK = 'calendar.this_week',
  LAST_WEEK = 'calendar.last_week',
  THIS_MONTH = 'calendar.this_month',
  LAST_MONTH = 'calendar.last_month',
  THIS_YEAR = 'calendar.this_year',
  LAST_YEAR = 'calendar.last_year',
  ALL_TIME = 'calendar.all_time',
  CUSTOM = 'calendar.custom',
}

export enum DateFormat {
  DD_MM_YYYY = 'dd-MM-yyyy',
  YYYY_MM_DD = 'yyyy-MM-dd',
  MM_DD_YYYY = 'MM_dd_yyyy',
}

export enum TimeFormat {
  HH_mm = 'HH:mm',
}
