import { createSelector } from 'reselect';
import { getDefaultValue } from './utils';
import type { ReportModelState } from './types';
import { ReportModelStateFields } from './types';
import { domain } from './index';

export const selectReportDomain = (state) => state[domain] as ReportModelState;

export const selectReportStatus = createSelector(
  selectReportDomain,
  (domain) => domain.get(ReportModelStateFields.REPORT_STATUS_RESPONSE),
);

export const selectReportData = createSelector(
  selectReportDomain,
  (_, args: { field: ReportModelStateFields }) => args.field,
  (domain: any, field: ReportModelStateFields) => {
    const reportData = domain.get(field) || false;

    if (!reportData) {
      return getDefaultValue(field);
    }

    return reportData;
  },
);
