import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type { ConfigInterface } from '@/services/interfaces';

export class ApiClientFrontendConfig extends ApiClientBase {
  async get() {
    const { data } = await this.fetchData<ConfigInterface>({
      queryString: 'frontend_config',
      apiVersion: 1,
    });

    return data;
  }
}
