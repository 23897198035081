import type { KioskUser } from '@/components/settings/Kiosk/types';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';

export class ApiClientKiosk extends ApiClientBase {
  async generate() {
    return await this.fetchData({
      queryString: 'kiosk/generate',
      method: 'POST',
    });
  }

  async user() {
    return await this.fetchData<KioskUser[]>({
      queryString: 'kiosk/user',
    });
  }
}
