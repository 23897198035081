import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import type {
  ISendFeedbackHelpdeskResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientHelpdesk/ApiClientHelpdesk.types';
import type { TicketEntry, TicketFiles } from '@/types/types';

export class ApiClientHelpdesk extends ApiClientBase {
  async sendFeedbackHelpdesk(body: TicketEntry) {
    try {
      const { data, status } = await this.fetchData<ISendFeedbackHelpdeskResponse>({
        method: 'POST',
        body,
        apiVersion: 3,
        queryString: 'proxy/helpdesk/tickets',
      });

      if (status !== 200) {
        throw new Error(`${status}`);
      }

      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async sendFeedbackHelpdeskFiles({ ticketUuid, files }: TicketFiles) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files[]', file, file.name);
    });

    const { data, status } = await this.fetchData({
      method: 'POST',
      body: formData,
      apiVersion: 3,
      queryString: `proxy/helpdesk/tickets/${ticketUuid}/files`,
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (status !== 200) {
      throw new Error(`${status}`);
    }

    return data;
  }
}
