
import type { IPeoplePickerParams } from '@/services/RestApiClientFactory/modules/ApiClientUsers/ApiClientUsers.types';
import { defaultFetchUsersAndGroupTreeParams } from '@/reactQuery/Users/fetchUsersAndGroupTree.utils';
import type { IUseQueryGetInviteLinkParams } from './hooks/useQueryGetInviteLink/useQueryGetInviteLink.types';

const PEOPLE_PICKER_KEY = 'PEOPLE_PICKER_KEY';
const USERS_AND_GROUP_TREE_KEY = 'USERS_AND_GROUP_TREE_KEY';

export const INVITE_USERS_BY_CSV_KEY = 'INVITE_USERS_BY_CSV_KEY';

export const getQueryPeoplePickerKey = (params: IPeoplePickerParams = defaultFetchUsersAndGroupTreeParams) => {
  const key = [PEOPLE_PICKER_KEY] as Array<string | IPeoplePickerParams>;

  if (params) {
    key.push(params);
  }

  return key;
};

export const getQueryUsersAndGroupTreeKey = () => [USERS_AND_GROUP_TREE_KEY];

export const getInviteLinkQueryKey = (params: IUseQueryGetInviteLinkParams) => ['INVITE_LINK_QUERY_KEY', params];

export const getUsersSettingKey = (params: {userId: string | number; settingName: string}) => ['USERS_SETTING', params];
