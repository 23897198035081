import { action } from 'typesafe-actions';
import { ReportModelActions } from './types';

export const onFilterChanged = (filters) =>
  action(ReportModelActions.ON_FILTER_CHANGED, filters);

export const initSummaryReport = (filters) =>
  action(ReportModelActions.INIT_SUMMARY_REPORT, filters);

export const fetchGroupedSummaryReport = (args) =>
  action(ReportModelActions.FETCH_GROUPED_SUMMARY_REPORT, args);

export const fetchGroupedSummaryReportSuccess = (data) =>
  action(ReportModelActions.FETCH_GROUPED_SUMMARY_REPORT_SUCCESS, data);

export const saveSummaryReportData = (data) =>
  action(ReportModelActions.SAVE_SUMMARY_REPORT_DATA, data);

export const fetchGroupedSummaryReportMetaSuccess = (data) =>
  action(ReportModelActions.FETCH_GROUPED_SUMMARY_REPORT_META_SUCCESS, data);

export const saveSummaryReportId = (reportId) =>
  action(ReportModelActions.SAVE_SUMMARY_REPORT_ID, reportId);

export const fetchReportStatusUpdated = (status) =>
  action(ReportModelActions.FETCH_REPORT_STATUS_UPDATED, status);

export const dataIsLoadingUpdated = (status) =>
  action(ReportModelActions.DATA_IS_LOADING_UPDATED, status);

export const setReportStatus = (reportStatus) =>
  action(ReportModelActions.SET_REPORT_STATUS_RESPONSE, reportStatus);

export const setReportFileStatus = (status) =>
  action(ReportModelActions.SET_REPORT_FILE_STATUS, status);

export const initDetailedReport = (filters) =>
  action(ReportModelActions.INIT_DETAILED_REPORT, filters);

export const saveDetailedReportId = (reportId) =>
  action(ReportModelActions.SAVE_DETAILED_REPORT_ID, reportId);

export const fetchDetailedReportSuccess = (data) => action(ReportModelActions.FETCH_DETAILED_REPORT_SUCCESS, data);

export const summaryReportSort = () => action(ReportModelActions.SUMMARY_REPORT_DATA_SORT);

export const refreshSummaryReport = () => action(ReportModelActions.REFRESH_SUMMARY_REPORT);

export const refreshDetailedReport = () => action(ReportModelActions.REFRESH_DETAILED_REPORT);
